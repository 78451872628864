import { Navigate, useLocation } from 'react-router-dom';
import { isValidToken } from '../utils/Jwt';

export default function AuthGuard({ children }) {
  const token = localStorage.getItem('token');
  const { pathname } = useLocation();

  if (!isValidToken(token)) {
    return <Navigate to='/login' state={pathname} />;
  }

  return <>{children}</>;
}
