import { Navigate } from 'react-router-dom';

import { isValidToken } from '../utils/Jwt';

export default function GuestGuard({ children }) {
  const token = localStorage.getItem('token');

  if (isValidToken(token)) {
    return <Navigate to='/app/download-apk' />;
  }

  return <>{children}</>;
}
