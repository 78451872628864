import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import GuestGuard from './guards/GuestGuard';
import AuthGuard from './guards/AuthGuard';
import AppLayout from './layouts/AppLayout';
import LoginLayout from './layouts/LoginLayout';

const DownloadApk = lazy(() => import('./pages/DownloadApk'));
const Login = lazy(() => import('./pages/Login'));

const AppRoutes = () => {
  return useRoutes([
    {
      path: '/',
      element: <LoginLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
      ],
    },
    {
      path: '/',
      element: <LoginLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/app',
      element: (
        <AuthGuard>
          <AppLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'download-apk',
          element: <DownloadApk />,
        },
      ],
    },
  ]);
};

export default AppRoutes;
