import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Spin } from 'antd';
import { Suspense } from 'react';

import AppRoutes from './routes';

const queryClient = new QueryClient();

function App() {
  return (
    <Suspense fallback={<Spin />}>
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
