import { Button, Layout } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';

import logo from '../assets/logo.svg';
import './index.css';

const { Header } = Layout;

const DashboardLayout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };
  return (
    <Layout>
      <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src={logo} alt='integra logo' height='50px' width='auto' />
        <Button icon={<LogoutOutlined />} onClick={handleLogout}>
          Logout
        </Button>
      </Header>

      <div className='main-content-view'>
        <Outlet />
      </div>
    </Layout>
  );
};

export default DashboardLayout;
